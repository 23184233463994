var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"6","md":"8","offset-md":"2","offset-lg":"3"}},[_c('v-row',[_c('v-col',[_c('h2',{staticClass:"text-center text-h4"},[_vm._v(" How do I login to my DStv Account? ")])])],1),_c('v-card',[_c('v-row',[_c('p',{staticClass:"px-10 pt-4"},[_vm._v(" You can log into your unique DStv account on either one of the following Platforms. ")]),_c('v-col',{staticClass:"px-10",attrs:{"cols":"12"}},[_c('v-expansion-panels',{attrs:{"multiple":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v(" 1. Login Online using your DStv Account ")]),_c('v-expansion-panel-content',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{attrs:{"color":"info","outlined":_vm.$vuetify.breakpoint
                                                    .mdAndDown,"prominent":_vm.$vuetify.breakpoint.lgAndUp,"text":"","icon":null}},[_c('p',[_c('v-icon',{attrs:{"color":"info"}},[_vm._v(_vm._s(_vm.oneIcon))]),_vm._v(" Open your mobile phone or computer browser and visit "),_c('a',{attrs:{"href":"https://www.dstvafrica.com/en-ng/dashboard","target":"_blank"}},[_vm._v("https://www.dstvafrica.com/en-ng/dashboard")])],1)])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{attrs:{"color":"info","outlined":_vm.$vuetify.breakpoint
                                                    .mdAndDown,"prominent":_vm.$vuetify.breakpoint.lgAndUp,"text":"","icon":null}},[_c('p',[_c('v-icon',{attrs:{"color":"info"}},[_vm._v(_vm._s(_vm.twoIcon))]),_vm._v(" Enter the "),_c('strong',[_vm._v("mobile phone number")]),_vm._v(" you used to register your DStv or your "),_c('strong',[_vm._v("surname")]),_vm._v(" on the account. if you don’t know your Dstv surname you can get it on nyangapay.com when you put in your "),_c('router-link',{attrs:{"to":{
                                                        name: _vm.$constants
                                                            .ROUTE_NAMES
                                                            .FAQ_WHAT_IS_MY_DSTV_SMARTCARD_NUMBER,
                                                    }}},[_vm._v("IUC/Smartcard number")])],1)])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{attrs:{"color":"info","outlined":_vm.$vuetify.breakpoint
                                                    .mdAndDown,"prominent":_vm.$vuetify.breakpoint.lgAndUp,"text":"","icon":null}},[_c('p',[_c('v-icon',{attrs:{"color":"info"}},[_vm._v(_vm._s(_vm.threeIcon))]),_vm._v(" Input on your "),_c('strong',[_vm._v("DStv smart card number/IUC")])],1)])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{attrs:{"color":"info","outlined":_vm.$vuetify.breakpoint
                                                    .mdAndDown,"prominent":_vm.$vuetify.breakpoint.lgAndUp,"text":"","icon":null}},[_c('p',[_c('v-icon',{attrs:{"color":"info"}},[_vm._v(_vm._s(_vm.fourIcon))]),_vm._v(" Tap the "),_c('code',[_vm._v("LOG IN")]),_vm._v(" button ")],1)])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-img',{staticClass:"mx-auto",attrs:{"max-height":"300","contain":"","src":require("@/assets/img/faq/dstv-login-1.jpg")}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{attrs:{"color":"success","outlined":_vm.$vuetify.breakpoint
                                                    .mdAndDown,"prominent":_vm.$vuetify.breakpoint.lgAndUp,"text":"","icon":null}},[_c('p',[_c('v-icon',{attrs:{"color":"success"}},[_vm._v(_vm._s(_vm.fiveIcon))]),_vm._v(" You will be greeted with an organized dashboard with your account information showing your viewing days and package. ")],1)])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-img',{staticClass:"mx-auto",attrs:{"max-height":"300","contain":"","src":require("@/assets/img/faq/dstv-login-2.jpg")}})],1)],1)],1),_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v(" 2. Login Using the MyDstv App ")]),_c('v-expansion-panel-content',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{attrs:{"color":"info","outlined":_vm.$vuetify.breakpoint
                                                    .mdAndDown,"prominent":_vm.$vuetify.breakpoint.lgAndUp,"text":"","icon":null}},[_c('p',[_c('v-icon',{attrs:{"color":"info"}},[_vm._v(_vm._s(_vm.oneIcon))]),_vm._v(" Visit the Google Play Store or App store on your phone and install the "),_c('code',[_vm._v("MyDstv")]),_vm._v(" app. ")],1)])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{attrs:{"color":"info","outlined":_vm.$vuetify.breakpoint
                                                    .mdAndDown,"prominent":_vm.$vuetify.breakpoint.lgAndUp,"text":"","icon":null}},[_c('p',[_c('v-icon',{attrs:{"color":"info"}},[_vm._v(_vm._s(_vm.twoIcon))]),_vm._v(" Open the "),_c('code',[_vm._v("MyDstv")]),_vm._v(" app and Enter your DStv mobile phone number. "),_c('br'),_c('em',[_vm._v(" You can log into your online account and check your dashboard for your Dstv phone number and email if subscribed using a reseller bought from a reseller ")])],1)])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{attrs:{"color":"success","outlined":_vm.$vuetify.breakpoint
                                                    .mdAndDown,"prominent":_vm.$vuetify.breakpoint.lgAndUp,"text":"","icon":null}},[_c('p',[_c('v-icon',{attrs:{"color":"success"}},[_vm._v(_vm._s(_vm.threeIcon))]),_vm._v(" Type in your surname and dstv smartcard number and tap on "),_c('code',[_vm._v("Login")])],1)])],1)],1)],1),_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v(" 3. Login Using DStv WhatsApp bot. ")]),_c('v-expansion-panel-content',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{attrs:{"color":"info","outlined":_vm.$vuetify.breakpoint
                                                    .mdAndDown,"prominent":_vm.$vuetify.breakpoint.lgAndUp,"text":"","icon":null}},[_c('p',[_c('v-icon',{attrs:{"color":"info"}},[_vm._v(_vm._s(_vm.oneIcon))]),_vm._v(" Open your mobile phone or computer browser and visit "),_c('a',{attrs:{"href":"https://www.dstvafrica.com/en-ng/dashboard","target":"_blank"}},[_vm._v("https://www.dstvafrica.com/en-ng/dashboard")])],1)])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{attrs:{"color":"info","outlined":_vm.$vuetify.breakpoint
                                                    .mdAndDown,"prominent":_vm.$vuetify.breakpoint.lgAndUp,"text":"","icon":null}},[_c('p',[_c('v-icon',{attrs:{"color":"info"}},[_vm._v(_vm._s(_vm.twoIcon))]),_vm._v(" Choose your country from the top corner of the website.(Nigeria in this Case) ")],1)])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{attrs:{"color":"info","outlined":_vm.$vuetify.breakpoint
                                                    .mdAndDown,"prominent":_vm.$vuetify.breakpoint.lgAndUp,"text":"","icon":null}},[_c('p',[_c('v-icon',{attrs:{"color":"info"}},[_vm._v(_vm._s(_vm.threeIcon))]),_vm._v(" Click on the WhatsApp icon on your bottom left corner of the site. ")],1)])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-img',{staticClass:"mx-auto",attrs:{"max-height":"300","contain":"","src":require("@/assets/img/faq/dstv-login-3.jpg")}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{attrs:{"color":"info","outlined":_vm.$vuetify.breakpoint
                                                    .mdAndDown,"prominent":_vm.$vuetify.breakpoint.lgAndUp,"text":"","icon":null}},[_c('p',[_c('v-icon',{attrs:{"color":"info"}},[_vm._v(_vm._s(_vm.fourIcon))]),_vm._v(" Chose "),_c('strong',[_vm._v("Open with WhatsApp")]),_vm._v(" and A chat with DStv WhatsApp account Will open with a message with "),_c('code',[_vm._v("“Hi Dstv”")]),_vm._v(" in your whatsapp input box. ")],1)])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-img',{staticClass:"mx-auto",attrs:{"max-height":"300","contain":"","src":require("@/assets/img/faq/dstv-login-4.jpg")}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{attrs:{"color":"info","outlined":_vm.$vuetify.breakpoint
                                                    .mdAndDown,"prominent":_vm.$vuetify.breakpoint.lgAndUp,"text":"","icon":null}},[_c('p',[_c('v-icon',{attrs:{"color":"info"}},[_vm._v(_vm._s(_vm.fiveIcon))]),_vm._v(" Send the “Hi DStv” message using whatsapp and you will get a reply asking you to send your smart card number. ")],1)])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{attrs:{"color":"info","outlined":_vm.$vuetify.breakpoint
                                                    .mdAndDown,"prominent":_vm.$vuetify.breakpoint.lgAndUp,"text":"","icon":null}},[_c('p',[_c('v-icon',{attrs:{"color":"info"}},[_vm._v(_vm._s(_vm.sixIcon))]),_vm._v(" After sending your smartcard number, you will be asked to enter your surname or the mobile phone number.(if you do not know your DStv surname you can use your IUC/Smartcard number to verify your surname on Nyanga pay) ")],1)])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{attrs:{"color":"success","outlined":_vm.$vuetify.breakpoint
                                                    .mdAndDown,"prominent":_vm.$vuetify.breakpoint.lgAndUp,"text":"","icon":null}},[_c('p',[_c('v-icon',{attrs:{"color":"success"}},[_vm._v(_vm._s(_vm.sevenIcon))]),_vm._v(" After sending your surname/mobile number on whatsapp, You will be automatically logged into DStv what’s app and you can now check your expiry date, check your package, change your package and even clear DStv errors from this chat. ")],1)])],1)],1)],1)],1)],1)],1)],1),_c('v-row',{staticClass:"mt-10"},[_c('back-button',{staticClass:"mx-auto"})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }